import React, { useEffect } from "react";
import "../pretixwidget.css";
const PretixWidget = ({ voucher }) => {
  useEffect(() => {
    // Stylesheet laden
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://pretix.eu/demo/democon/widget/v1.css";
    link.crossOrigin = "anonymous";
    document.head.appendChild(link);

    // Script laden
    const script = document.createElement("script");
    script.src = "https://pretix.eu/widget/v1.en.js";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    // Cleanup (optional)
    return () => {
      document.head.removeChild(link);
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <pretix-widget
        event="https://pretix.eu/fcfg/darts2025"
        single-item-select="button"
        skip-ssl-check
        {...(voucher ? { voucher } : {})}
      ></pretix-widget>
      <noscript>
        <div className="pretix-widget">
          <div className="pretix-widget-info-message">
            JavaScript ist in Ihrem Browser deaktiviert. Um unseren Ticketshop
            ohne JavaScript aufzurufen, klicken Sie bitte
            <a
              target="_blank"
              rel="noopener"
              href="https://pretix.eu/fcfg/darts2025"
            >
              hier
            </a>
            .
          </div>
        </div>
      </noscript>
    </div>
  );
};

export default PretixWidget;
