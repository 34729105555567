import React, { useEffect, useState } from "react";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db, auth } from "../firebase";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { saveAs } from "file-saver";

const TeamListPage = () => {
  const [teams, setTeams] = useState([]);
  const [expandedTeam, setExpandedTeam] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState("");
  const [acceptedCount, setAcceptedCount] = useState(0);

  useEffect(() => {
    const checkAuthStatus = () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      });
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    const fetchTeams = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "registrations"));
        const teamsData = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
        setTeams(teamsData);

        const acceptedTeams = teamsData.filter((team) => team.isAccepted);
        setAcceptedCount(acceptedTeams.length);

        // Speichern der Teams in einer JSON-Datei
        // const json = JSON.stringify(teamsData, null, 2);
        // const blob = new Blob([json], { type: "application/json" });
        // saveAs(blob, "teamsData.json");
      } catch (error) {
        console.error("Fehler beim Abrufen der Teams: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchTeams();
    }
  }, [isAuthenticated]);

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsAuthenticated(true);
        setAuthError("");
      })
      .catch((error) => {
        setAuthError(
          "Fehler bei der Anmeldung. Bitte überprüfen Sie Ihre Zugangsdaten."
        );
        console.error("Anmeldefehler:", error);
      });
  };

  const toggleExpand = (teamId) => {
    setExpandedTeam(expandedTeam === teamId ? null : teamId);
  };

  const handleAccept = async (teamId, e) => {
    e.stopPropagation();
    const team = teams.find((team) => team.id === teamId);
    const newStatus = team.isAccepted === true ? null : true;

    const teamRef = doc(db, "registrations", teamId);
    await updateDoc(teamRef, { isAccepted: newStatus });

    setTeams((prevTeams) =>
      prevTeams.map((team) =>
        team.id === teamId ? { ...team, isAccepted: newStatus } : team
      )
    );
    setAcceptedCount(
      (prevCount) => prevCount + (newStatus ? 1 : team.isAccepted ? -1 : 0)
    );
  };

  const handleReject = async (teamId, e) => {
    e.stopPropagation();
    const team = teams.find((team) => team.id === teamId);
    const newStatus = team.isAccepted === false ? null : false;

    const teamRef = doc(db, "registrations", teamId);
    await updateDoc(teamRef, { isAccepted: newStatus });

    setTeams((prevTeams) =>
      prevTeams.map((team) =>
        team.id === teamId ? { ...team, isAccepted: newStatus } : team
      )
    );
    setAcceptedCount(
      (prevCount) =>
        prevCount + (newStatus ? 1 : team.isAccepted === true ? -1 : 0)
    );
  };

  return (
    <section className="p-4 md:p-8 bg-gray-900 text-white min-h-screen overflow-hidden">
      {isAuthenticated ? (
        isLoading ? (
          <p className="text-center">Lädt...</p>
        ) : teams.length === 0 ? (
          <p className="text-center">Keine Teams gefunden</p>
        ) : (
          <div className="w-full overflow-x-auto max-w-full">
            <h2 className="text-3xl md:text-5xl font-semibold mb-8 text-center">
              Alle Teams
            </h2>
            <table className="table-fixed w-full bg-gray-800 rounded-lg shadow-lg text-xs sm:text-base">
              <thead>
                <tr className="bg-gray-700 text-gray-300 text-left uppercase tracking-wider">
                  <th className="px-2 sm:px-4 py-2 sm:py-4 w-8 whitespace-nowrap">
                    #
                  </th>
                  <th className="px-2 sm:px-4 py-2 sm:py-4 whitespace-nowrap">
                    Team
                  </th>
                  <th className="px-2 sm:px-4 py-2 sm:py-4 whitespace-nowrap">
                    Spieler 1
                  </th>
                  <th className="px-2 sm:px-4 py-2 sm:py-4 whitespace-nowrap">
                    Spieler 2
                  </th>
                  <th className="px-2 sm:px-4 py-2 sm:py-4 whitespace-nowrap">
                    Timestamp
                  </th>
                  <th className="px-2 sm:px-4 py-2 sm:py-4 whitespace-nowrap">
                    Akzeptiert: {acceptedCount}/24
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-300">
                {teams.map((team, index) => (
                  <tr
                    key={team.id}
                    onClick={() => toggleExpand(team.id)}
                    className="cursor-pointer border-b border-gray-600 hover:bg-gray-700 transition"
                  >
                    <td className="px-2 sm:px-4 py-2 sm:py-4 w-8">
                      {teams.length - index}
                    </td>
                    <td className="px-2 sm:px-4 py-2 sm:py-4 overflow-hidden text-ellipsis max-w-[10rem]">
                      {team.teamName}
                    </td>
                    <td className="px-2 sm:px-4 py-2 sm:py-4 overflow-hidden text-ellipsis max-w-[10rem]">
                      {team.player1.firstName} {team.player1.lastName}
                    </td>
                    <td className="px-2 sm:px-4 py-2 sm:py-4 overflow-hidden text-ellipsis max-w-[10rem]">
                      {team.player2.firstName} {team.player2.lastName}
                    </td>
                    <td className="px-2 sm:px-4 py-2 sm:py-4 text-ellipsis max-w-[10rem]">
                      {new Date(team.timestamp.seconds * 1000).toLocaleString(
                        "de-DE",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </td>
                    <td className="px-2 sm:px-4 py-2 sm:py-4 flex space-x-2">
                      <button
                        onClick={(e) => handleAccept(team.id, e)}
                        className={`font-bold py-2 px-4 rounded flex items-center space-x-2 ${
                          team.isAccepted
                            ? "bg-green-600 text-white"
                            : "bg-gray-600 text-gray-300 hover:bg-green-500"
                        }`}
                      >
                        👍
                      </button>
                      <button
                        onClick={(e) => handleReject(team.id, e)}
                        className={`font-bold py-2 px-4 rounded flex items-center space-x-2 ${
                          team.isAccepted === false && team.isAccepted !== null
                            ? "bg-red-600 text-white"
                            : "bg-gray-600 text-gray-300 hover:bg-red-500"
                        }`}
                      >
                        👎
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {expandedTeam && (
              <div
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                onClick={() => setExpandedTeam(null)}
              >
                <div
                  className="bg-gray-800 p-6 md:p-8 rounded-lg shadow-lg text-gray-300 w-11/12 md:max-w-2xl relative"
                  onClick={(e) => e.stopPropagation()}
                >
                  <button
                    onClick={() => setExpandedTeam(null)}
                    className="text-gray-400 hover:text-gray-100 absolute top-4 right-4 text-2xl font-bold"
                  >
                    &times;
                  </button>
                  <div className="space-y-4 md:space-y-6">
                    <div className="border-b border-gray-600 pb-4">
                      <p className="text-xl font-semibold">
                        Team:{" "}
                        {
                          teams.find((team) => team.id === expandedTeam)
                            ?.teamName
                        }
                      </p>
                      <p>
                        <strong>Einlaufmusik:</strong>{" "}
                        {
                          teams.find((team) => team.id === expandedTeam)
                            ?.entryMusic
                        }
                      </p>
                      <p className="break-words">
                        <strong>Link zur Einlaufmusik:</strong>{" "}
                        <a
                          href={
                            teams.find((team) => team.id === expandedTeam)
                              ?.entryMusicLink
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-400 underline"
                        >
                          {
                            teams.find((team) => team.id === expandedTeam)
                              ?.entryMusicLink
                          }
                        </a>
                      </p>
                    </div>
                    <div className="border-b border-gray-600 pb-4">
                      <p className="text-lg font-semibold">
                        Spieler 1 Details:
                      </p>
                      <p>
                        <strong>Name:</strong>{" "}
                        {
                          teams.find((team) => team.id === expandedTeam)
                            ?.player1.firstName
                        }{" "}
                        {
                          teams.find((team) => team.id === expandedTeam)
                            ?.player1.lastName
                        }
                      </p>
                      <p>
                        <strong>Geburtsdatum:</strong>{" "}
                        {
                          teams.find((team) => team.id === expandedTeam)
                            ?.player1.date
                        }
                      </p>
                      <p>
                        <strong>Email:</strong>{" "}
                        {
                          teams.find((team) => team.id === expandedTeam)
                            ?.player1.email
                        }
                      </p>
                      <p>
                        <strong>Vereinsspieler:</strong>{" "}
                        {teams.find((team) => team.id === expandedTeam)?.player1
                          .clubPlayer
                          ? "Ja"
                          : "Nein"}
                      </p>
                    </div>
                    <div>
                      <p className="text-lg font-semibold">
                        Spieler 2 Details:
                      </p>
                      <p>
                        <strong>Name:</strong>{" "}
                        {
                          teams.find((team) => team.id === expandedTeam)
                            ?.player2.firstName
                        }{" "}
                        {
                          teams.find((team) => team.id === expandedTeam)
                            ?.player2.lastName
                        }
                      </p>
                      <p>
                        <strong>Geburtsdatum:</strong>{" "}
                        {
                          teams.find((team) => team.id === expandedTeam)
                            ?.player2.date
                        }
                      </p>
                      <p>
                        <strong>Email:</strong>{" "}
                        {
                          teams.find((team) => team.id === expandedTeam)
                            ?.player2.email
                        }
                      </p>
                      <p>
                        <strong>Vereinsspieler:</strong>{" "}
                        {teams.find((team) => team.id === expandedTeam)?.player2
                          .clubPlayer
                          ? "Ja"
                          : "Nein"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      ) : (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg w-11/12 max-w-md text-gray-300">
            <h3 className="text-2xl font-semibold mb-4">
              Anmeldung erforderlich
            </h3>
            {authError && <p className="text-red-500 mb-4">{authError}</p>}
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-Mail"
              className="w-full p-3 rounded-md bg-gray-800 border border-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 mb-4"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Passwort"
              className="w-full p-3 rounded-md bg-gray-800 border border-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 mb-4"
            />
            <button
              onClick={handleLogin}
              className="w-full bg-gray-700 hover:bg-gray-600 text-white font-bold py-3 rounded-full transition duration-300"
            >
              Anmelden
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default TeamListPage;
