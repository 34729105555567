import React, { useRef, useState, useEffect } from "react";
import { useOnScreen } from "../customHooks";
import PretixWidget from "./PretixWidget"; // Importiere die PretixWidget-Komponente

const EventDescriptionSection = ({ ticketAvailableDate }) => {
  const imageRef1 = useRef();
  const isVisible1 = useOnScreen(imageRef1);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const [isTicketAvailable, setIsTicketAvailable] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // URL-Parameter lesen und Gutschein-Code extrahieren
  const urlParams = new URLSearchParams(window.location.search);
  const voucherCode = urlParams.get("voucher");

  // Countdown-Berechnung
  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = ticketAvailableDate - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / (1000 * 60)) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      } else {
        setIsTicketAvailable(true);
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [ticketAvailableDate]);

  const openWidget = () => {
    setIsWidgetOpen(true);
    document.body.style.overflow = "hidden"; // Deaktiviert Scrollen
  };
  const closeWidget = () => {
    setIsWidgetOpen(false);
    document.body.style.overflow = "auto"; // Reaktiviert Scrollen
  };

  return (
    <section
      id="eventDescriptionSection"
      className="flex flex-col lg:flex-row items-center lg:items-start justify-between bg-gray-900 p-8 text-center lg:text-left rounded-lg my-8 w-full max-w-6xl mx-auto"
    >
      {/* Bildbereich */}
      <div
        ref={imageRef1}
        className={`w-full lg:w-1/2 flex justify-center lg:justify-start mb-6 lg:mb-0 ${
          isVisible1 ? "animate-slideInLeft" : ""
        }`}
      >
        <img
          src="/images/_2500337.JPG"
          alt="Event location"
          className="w-full lg:w-[500px] lg:h-[350px] object-cover rounded-lg shadow-lg"
        />
      </div>

      {/* Eventdetails */}
      <div className="w-full lg:w-1/2 space-y-4">
        <h2 className="text-5xl font-semibold mb-8">Zuschauer</h2>
        <div className="text-center lg:text-left">
          <p className="text-lg">📍 HallyPally</p>
          <p className="text-lg">📅 11. Januar 2025</p>
          <p className="text-lg">🕒 ab 14:00 Uhr</p>
          <p className="text-lg">💸 10,00 € Vorverkauf</p>
        </div>
        <p className="text-lg max-w-xl mt-8 text-left">
          Erlebe die ultimative Darts Gala und einen unvergesslichen Tag voller
          Spannung und Unterhaltung – vom klassischen "Ally Pally"-Feeling über
          eine Live-Übertragung der Hauptbühne bis hin zu Aftershow-Party und
          exklusiven Getränkespecials. Für das beste Einzel und das kreativste
          Gruppenkostüm gibt es tolle Prämien zu gewinnen! Sichere dir jetzt
          deinen Platz und sei dabei, wenn die Pfeile fliegen!
        </p>

        {/* Countdown oder Button „Ticket kaufen“ */}
        {isTicketAvailable ? (
          <button
            onClick={openWidget}
            className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-3 px-10 rounded-full transition duration-300 mt-4"
          >
            Ticket kaufen
          </button>
        ) : (
          <div className="mt-4">
            <p className="text-lg">Tickets verfügbar in:</p>
            <p className="text-3xl font-bold mt-2">
              {/* TODO: Prüfen bei none:   */}
              {timeLeft.days}t {timeLeft.hours}s {timeLeft.minutes}m{" "}
              {timeLeft.seconds}s
            </p>
          </div>
        )}
      </div>

      {/* Pretix Widget als Modal
      TODO: Body scroll deak bei isWidgetopen = true
      */}
      {isWidgetOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 overflow-y-auto"
          style={{ zIndex: 100 }}
        >
          <div className="bg-gray-900 rounded-lg p-6 w-full relative">
            <button
              onClick={closeWidget}
              className="text-gray-400 hover:text-gray-300 absolute top-4 right-4 text-2xl"
            >
              &times;
            </button>
            {/* Pretix Widget */}
            <PretixWidget voucher={voucherCode} />
          </div>
        </div>
      )}
    </section>
  );
};

export default EventDescriptionSection;
