import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDu-qnc9tEWWNjgMVsoumNB0G3Xlr_k2OI",
  authDomain: "fcfg-dartsgala.firebaseapp.com",
  projectId: "fcfg-dartsgala",
  storageBucket: "fcfg-dartsgala.appspot.com",
  messagingSenderId: "237262055108",
  appId: "1:237262055108:web:173c511166f03c0a37cb91",
  measurementId: "G-G2KKQ9C3PH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth, collection, addDoc, getDocs, where, query };
